import { ResponsiveValue } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { ComponentPartsHeader, UploadFileEntityResponse } from './cms'

export type ImgProps = UploadFileEntityResponse;

export interface LinkProps {
  href: string;
  target: '_self' | '_blank' | '_parent' | '_top';
}

export interface CtaProps {
  text: string;
  link: LinkProps;
  variant: 'solid' | 'outline';
  reversed: boolean;
}

export type HeaderProps = ComponentPartsHeader;

export enum IMAGE_POSITION {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ContentSectionProps {
  title?: string | ReactNode;
  description?: string;
  cta?: CtaProps;
  color?: string;
  img: any;
  img_mobile: any;
  img_position: IMAGE_POSITION;
  bg_color: string;
  gap?: ResponsiveValue<number | string>;
  textPx?: ResponsiveValue<number | string>;
  my?: ResponsiveValue<number | string>;
  textWith?: ResponsiveValue<number | string>;
}

export interface StorySectionProps {
  title: string;
  description: string;
  author: string;
  img: ImgProps;
  img_mobile: ImgProps;
  story_img_position: 'left' | 'right';
}
