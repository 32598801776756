import {Text} from '@chakra-ui/react'
import {useTranslation} from 'next-i18next'
import ContentSection from '_app/components/Content/ContentSection'
import Page from '_app/components/Page/Page'
import {IMAGE_POSITION} from '_app/types/content'
import {Context} from '_app/types/context'
import SeoHelper from '../helpers/SeoHelper/SeoHelper'
import I18nHelper from '../helpers/I18nHelper/I18nHelper'
import {APP_ROUTES} from '../_app/routes'

export default function Custom404() {
    const {t} = useTranslation('error_page')
    const propsData = {
        img: {
            __typename: 'UploadFileEntityResponse',
            data: {
                __typename: 'UploadFileEntity',
                id: '34',
                attributes: {
                    __typename: 'UploadFile',
                    alternativeText: '404.webp',
                    url: '/images/404.webp',
                    width: 2160,
                    height: 2160,
                },
            },
        },
        img_mobile: {
            __typename: 'UploadFileEntityResponse',
            data: {
                __typename: 'UploadFileEntity',
                id: '34',
                attributes: {
                    __typename: 'UploadFile',
                    alternativeText: '404.webp',
                    url: '/images/404.webp',
                    width: 2160,
                    height: 2160,
                },
            },
        },
        img_position: IMAGE_POSITION.LEFT,
        bg_color: '#fff',
    }
    return (
        <Page
            seo={{
                title: SeoHelper.getTitle('404'),
                description: SeoHelper.getDescription(),
            }}
            hasNoIndex={true}
            hasNoFollow={true}
        >
            <ContentSection
                {...propsData}
                title={
                    <>
                        <Text
                            as="span"
                            display="block"
                            fontSize={{
                                base: '100px',
                                lg: '160px',
                            }}
                        >
                            404
                        </Text>
                        <Text
                            sx={{marginBottom: '20px'}}
                        >
                            {t('PAGE_NOT_FOUND')}
                        </Text>
                    </>
                }
                cta={{
                    link: {
                        absolute: true,
                        href: APP_ROUTES.INDEX,
                        target: '_self',
                    },
                    reversed: false,
                    variant: 'outline',
                    text: t('BACK_TO_MAIN_PAGE'),
                }}
            />
        </Page>
    )
}

export const getStaticProps = async (ctx: Context) => {
    return {
        props: {
            ...(await I18nHelper.getI18nProps(ctx, ['error_page'])),
        },
    }
}
